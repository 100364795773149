<template>
	<div class="package-buy">
		<van-nav-bar
			title="疫境求真套餐購買"
			left-arrow
			@click-left="$router.push('/agent')"
			class="qjc-agent-navbar"
		></van-nav-bar>
		
		<div class="count-list">
			<van-button @click="checkTimes(5)" :class="{ active: times == 5 }" :icon="times==5?'success':''">5次</van-button>
			<van-button @click="checkTimes(10)" :class="{ active: times == 10 }" :icon="times==10?'success':''">10次</van-button>
			<van-button @click="checkTimes(15)" :class="{ active: times == 15 }" :icon="times==15?'success':''">15次</van-button>
		</div>
		
		<van-field
			v-model="custom"
			placeholder="自定義，請輸入（最低5次）"
			type="number"
			class="custom"
			:error="customErr"
			@input="customTimes"
			@blur="verifyTimes"
		></van-field>
		
		<div class="info qjc-clearfix">
			<p class="qjc-fl">
				套餐庫存次數：<span class="qjc-c-primary">{{ residue }}次</span>
			</p>
			<p class="qjc-fr">
				支付金额：<span class="qjc-c-primary">HKD ${{ times?accMul(price, times):0 }}</span>
			</p>
		</div>
		
		<van-button @click="selWay" class="buy-btn qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
			<span class="qjc-vertical-m">立即支付</span>
			<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
		</van-button>
		
		<van-popup v-model="payWayShow" class="pay-way qjc-texta-l" position="bottom">
			<p class="title qjc-fts-32 qjc-c-dark qjc-texta-c">選擇支付方式</p>
			
			<van-radio-group v-model="payWay" checked-color="#6681FA" icon-size="0.36rem">
			  <van-cell-group>
			    <van-cell class="wx qjc-fts-32 qjc-c-dark" :icon="require('@/assets/images/agent_pay_wx.png')" title="微信支付" clickable @click="payWay = '1'">
			      <template #right-icon>
			        <van-radio name="1" />
			      </template>
			    </van-cell>
			    <van-cell class="paypal qjc-fts-32 qjc-c-dark" :icon="require('@/assets/images/agent_pay_paypal.png')" title="Palpay支付" clickable @click="payWay = '2'">
			      <template #right-icon>
			        <van-radio name="2" />
			      </template>
			    </van-cell>
			  </van-cell-group>
			</van-radio-group>
			
			<van-button @click="pay" :loading="loading" :disabled="loading" class="buy-btn qjc-block qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
				<span class="qjc-vertical-m">確定支付</span>
				<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
			</van-button>
			
		</van-popup>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Button, Field, Toast, Popup, RadioGroup, Radio, Cell, CellGroup } from 'vant'
	
	Vue.use(NavBar)
	   .use(Button)
	   .use(Field)
	   .use(Toast)
	   .use(Popup)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(Cell)
	   .use(CellGroup);
	   
	import { accMul } from '@/js/utils'
	import { comboBuy, agentInfo } from '@/js/axios'
	
	export default{
		name: 'agentPackageBuy',
		data() {
			return {
				times: 5,// 默认20次
				
				custom: '',// 自定义次数
				customErr: false,
				
				residue: 0,// 套餐剩余次数
				price: 0,// 支付单价金额
				
				isWx: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,
				
				payWayShow: false,// 支付方式是否弹出
				payWay: '',// 支付方式
				
				loading: false// 提交中
			}
		},
		created() {
			// 如本地存储过次数，赋值
			var buyTimes = sessionStorage.getItem('buyTimes');
			if(buyTimes){
				this.times = buyTimes;
				this.custom = buyTimes;
			}
			
			// 微信内支付二次跳转
			var code = this.$route.query.code;
			if(this.isWx && code){
				let data = {
					times: this.times,
					iswx: 1,
					code: code
				};
				
				comboBuy(data).then(res => {
					console.log(res)
					if(res.code == 200){
						var result = JSON.parse(atob(res.data));
						
						var redirectUrl = location.origin + '/' + this.$router.resolve({
							path: "/agent"
						}).href;
						
						location.href = result.url + '&redirect_url=' + encodeURIComponent(redirectUrl);
					}else{
						Toast.fail(res.msg);
					}
				});
			}
		},
		mounted() {
			agentInfo().then(res => {
				if(res.code == 200){
					// 存储用户信息
					var userInfo = res.data; 
			
					this.residue = userInfo.leftTimes;
					this.price = userInfo.price;
				}else{
					Toast.fail(res.msg);
				}
			});

		},
		methods: {
			accMul,
			
			// 选择支付方式
			selWay() {
				if(this.times >= 5){
					this.payWayShow = true;
				}
			},
			
			// 支付
			pay() {
				if(this.times >= 5){
					let data = {
						times: this.times
					};
					
					if(!this.payWay){
						Toast.fail('請選擇支付方式');
					}else if(!this.isWx && this.payWay == '1'){//微信外浏览器使用微信支付提示
						Toast.fail('微信支付僅在微信內可用');
					}else{
						if(this.payWay == '1'){
							data.iswx = 1;
						}
						
						this.loading = true;
						
						comboBuy(data).then(res => {
							this.loading = false;
							if(res.code == 200){
								var result = JSON.parse(atob(res.data));
								// console.log(result)
								sessionStorage.setItem('buyTimes', this.times);
								location.href = result.url;
							}else{
								Toast.fail(res.msg);
							}
						});
						
					}
					
				}else{
					Toast.fail('最低5次');
				}
			},
			
			// 自定义次数
			customTimes(times) {
				this.checkTimes(times);
			},
			
			verifyTimes() {
				if(this.times < 5){
					this.customErr = true;
					
					this.checkTimes('');
				}
			},
			
			// 更改次数
			checkTimes(times) {
				// 只能为整数
				var times = parseInt(times);
				
				// 最大99
				if(times > 99){
					times = 99;
				}
				
				this.times = times;
				
				// 若自定义不为空，同步
				if(this.custom){
					this.custom = times;
				}
				
				if(this.times >= 5){
					this.customErr = false;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.package-buy{
		
		&>div{
			margin: 0.24rem 0.24rem 0;
		}
		
		.count-list{
			display: flex;
			justify-content: space-between;
			
			.van-button{
				position: relative;
				width: 2.23rem;
				height: 0.88rem;
				line-height: 0.88rem;
				color: #3B4549;
				font-size: 0.3rem;
				border-radius: 0.08rem;
				transition: all .3s;
				overflow: hidden;
				
				&:active::before{
					background-color: transparent;
				}
				&.active{
					color: #6883FB;
					border-color: #6883FB;
					background-color: #F9FAFF;
					font-weight: 500;
					transition: all .3s;
					
					.van-icon{
						position: absolute;
						right: 0;
						bottom: 0;
						width: 0.48rem;
						height: 0.48rem;
						background-color: #6883FB;
						color: #fff;
						font-size: 0.16rem;
						text-align: left;
						line-height: 0.5rem;
						transform: translate(50%, 50%)rotateZ(45deg);
						
						&::before{
							transform: rotateZ(-45deg);
						}
					}
				}
			}
		}
		
		.custom{
			width: auto;
			border: 0.01rem solid #BBC8CC;
			border-radius: 0.08rem;
			font-size: 0.3rem;
			color: #3B4549;
		}
	
		.info{
			font-weight: 500;
			font-size: 0.26rem;
			padding: 0 0.08rem;
			margin-top: 0.16rem;
		}
		
		.buy-btn{
			width: 6.86rem;
			height: 0.87rem;
			line-height: 1;
			border-radius: 0.08rem;
			margin: 0.68rem auto 0.18rem;
			padding: 0 0 0 0.2rem;
			
			img{
				width: 0.59rem;
				height: auto;
				margin: -0.1rem 0 0 0.11rem;
			}
		}
		
		.pay-way{
			margin: 0;
			padding-bottom: 0.48rem;
			
			.title{
				line-height: 0.32rem;
				padding: 0.36rem 0 0.4rem;
			}
			.van-cell-group{
				margin-bottom: 1.28rem;
				
				&::after{
					border: none;
				}
				
				.van-cell{
					align-items: center;
					padding: 0 0.4rem;
					line-height: 1.06rem;
					
					&::after{
						border: none;
					}
					
					.van-icon{
						margin-right: 0.08rem;
						
						.van-image{
							width: 100%;
							height: 100%;
						}
					}
					&.wx{
						.van-icon{
							width: 0.59rem;
							height: 0.49rem;
						}
					}
					&.paypal{
						.van-icon{
							width: 0.42rem;
							height: 0.53rem;
						}
					}
				}
			
			}
			
		}
		
	}
</style>
